import { Badge, Col, Grid, Layout, Modal } from 'antd';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';
import { notificationCountState } from '../../recoil/notificationCount';
import { GetVideoIdFromRecoil } from '../../recoil/playlist';
import { privacyTypeState } from '../../recoil/privacyType';
import {
  getPrivacyId,
  getPrivacyMultiSession,
  getPrivacyType
} from '../../utils/functions/confidentiality';
import { getContent, handleSideBar } from '../../utils/functions/contentParams';
import { getCurrentItemFromPlaylist } from '../../utils/functions/player';
import useWindowSize from '../../utils/hooks/useWindowSize';
import Header from '../Header';
import { ShowSidebarIcon } from '../Icons';
import MultiSession from '../MultiSession';
import Poll from '../Poll';
import TabsPlayer from '../Tabs';
import Discussion from '../Discussion';

const VideoPlayer = dynamic(() => import('../Player/index.js'), {
  ssr: false
});
const Description = dynamic(() => import('../Description/index'), {
  ssr: false
});

const { Content, Sider } = Layout;
const { useBreakpoint } = Grid;

const Main = function ({ live, media, playlist, isEmbed, webtv }) {
  const { content, contentType } = getContent(media, live, playlist);
  const { shouldShowPoll, shouldShowSideBar, sidebarHasContent } =
    handleSideBar(content, contentType);
  const [isMuted, setIsMuted] = useState(false);
  const [disableAnalytic, setDisableAnalytic] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [mode, setMode] = useState('normal');
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [height, setHeight] = useState(0);
  const [title, setTitle] = useState();
  const [videoLanguage, setVideoLanguage] = useState();
  const [layoutStyle, setLayoutStyle] = useState();
  const [pollId, setPollId] = useState();

  const screens = useBreakpoint();
  const router = useRouter();

  const currentPlaylistContentId = GetVideoIdFromRecoil();
  const { pageTheme, videoTitle, accentColor } = GetBrandSettingFromRecoil();

  const [privacy, setPrivacy] = useRecoilState(privacyTypeState);
  const count = useRecoilValue(notificationCountState);

  const disableMultiSession = getPrivacyMultiSession({ content });
  const privacyId = getPrivacyId({ content });

  const { width: windowWidth } = useWindowSize();

  const webTvId = webtv?.id;
  const currentDate = new Date();
  const handleClickSidebar = () => {
    if (mode === 'large') {
      const vjsModeControl =
        document.getElementsByClassName('vjs-mode-control')[0];
      if (vjsModeControl) {
        vjsModeControl.classList.remove('vjs-mode');
      }
      setMode('normal');
      setCollapsed(false);
    } else {
      setCollapsed(!collapsed);
    }
  };

  function setHeaderHeight() {
    const breadcrumb = document.getElementById('breadcrumb');
    if (breadcrumb) {
      const breadCrumbBounding = breadcrumb.getBoundingClientRect();
      const breadcrumbVisible =
        breadCrumbBounding.height + breadCrumbBounding.top;
      setHeight(breadcrumbVisible);
    } else {
      const header = document.getElementById('header');
      if (header) {
        const headerBounding = header.getBoundingClientRect();
        const headerHeight = headerBounding.height + headerBounding.top;
        setHeight(headerHeight < 0 ? 0 : headerHeight);
      }
    }
  }

  useEffect(() => {
    const privacity = getPrivacyType({ content });
    setPrivacy(privacity);
  }, [content]);

  useEffect(() => {
    if (shouldShowSideBar && sidebarHasContent) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [shouldShowSideBar, webTvId, screens]);

  useEffect(() => {
    const vjsModeControl =
      document.getElementsByClassName('vjs-mode-control')[0];
    if (!collapsed) {
      setMode('normal');
      vjsModeControl && vjsModeControl.classList.remove('vjs-mode');
    }
  }, [collapsed]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.dispatchEvent(new Event('scroll'));
    }, 500);
    return () => clearTimeout(timeout);
  }, [collapsed]);

  useEffect(() => {
    if (webTvId) return;
    if (live?.status === 'Ended' && live?.hasReplay && live?.replay?.id) {
      setShouldRedirect(true);
      // TODO prefetech the next router page with <Link>
      const timeout = setTimeout(() => {
        if (isEmbed) {
          window.location = `/embed/media/${live.replay.id}`;
        } else {
          window.location = `/media/${live.replay.id}`;
        }
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [live, isEmbed, router, webTvId]);

  useEffect(() => {
    if (!collapsed) {
      setMode('normal');
    }
  }, [collapsed]);

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', setHeaderHeight);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', setHeaderHeight);
    };
  });

  // set the video title because there is some logic to do with playlist
  useEffect(() => {
    if (!content || !contentType) return;
    if (contentType !== 'playlist') {
      setTitle(videoTitle);
      setPollId(content?.live?.poll?.id || content?.poll?.id);
    } else {
      const item = getCurrentItemFromPlaylist(
        content,
        currentPlaylistContentId
      );
      setTitle(item?.pageSetting?.videoTitle);
      setVideoLanguage(item?.language);
      setPollId(item?.poll?.id);
    }
  }, [content, contentType, videoTitle, currentPlaylistContentId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const disableAnalyticParam = urlParams.get('disableAnalytic');
    if (disableAnalyticParam === 'true') {
      setDisableAnalytic(true);
    }
  }, []);

  useEffect(() => {
    const styleTmp = {
      overflow: screens.sm ? 'inherit' : 'hidden',
      minHeight: screens.sm ? '100vh' : 'inherit'
    };
    if (!screens.sm && !webTvId) {
      if (CSS.supports('max-height', '100svh')) {
        styleTmp.height = '100svh';
      } else {
        styleTmp.height = '100vh';
      }
    }
    setLayoutStyle(styleTmp);
  }, [screens, webTvId]);

  // MEDIAS & PLAYLISTS & LIVE

  const pageTest = router.pathname === '/test';

  return (
    <>
      <Layout
        style={{
          ...layoutStyle,
          maxWidth: '1024px',
          height: '100%',
          minHeight: '100vh',
          margin: 'auto',
          display: 'flex',
          backgroundColor: '#fff !important'
        }}
        data-cy="layout"
      >
        {!isEmbed && !webTvId && <Header />}
        {
          <Layout
            style={{
              width: '100%',
              position: 'relative',
              margin: 0,
              backgroundColor: '#fff !important',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Content
              style={{
                width: '65%'
              }}
            >
              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  maxWidth: '100%'
                }}
              >
                {pollId && (
                  <div
                    style={{
                      display:
                        !screens.sm ||
                        (!shouldShowPoll && contentType === 'media')
                          ? 'none'
                          : 'block'
                    }}
                  >
                    <Poll
                      pollId={pollId}
                      isMuted={isMuted}
                      contentType={contentType}
                    />
                  </div>
                )}

                <div
                  style={{
                    position: 'relative',
                    maxWidth: '100vw'
                  }}
                >
                  <VideoPlayer
                    media={media}
                    live={live}
                    playlist={playlist}
                    webtv={webtv}
                    isEmbed={isEmbed}
                    collapsed={mode === 'large' ? true : collapsed}
                    mode={mode}
                    sidebarHasContent={sidebarHasContent}
                    setMode={setMode}
                    shouldRedirect={shouldRedirect}
                    disableAnalytic={disableAnalytic}
                    isMuted={isMuted}
                    setIsMuted={setIsMuted}
                    fromWebTV={!!webTvId}
                  />

                  {/* {sidebarHasContent && screens.sm && (
                <div className="sidebar-aside" onClick={handleClickSidebar}>
                  <div className="sidebar-background" />
                  <div className="sidebar-badge">
                    <Badge
                      size="small"
                      count={count}
                      data-cy="discussion-count"
                    >
                      <div className="sidebar-icon">
                        <ShowSidebarIcon
                          style={{
                            fontSize: 20,
                            color: accentColor
                          }}
                        />
                      </div>
                    </Badge>
                  </div>
                </div>
              )} */}
                </div>
              </div>
              <Col
                span={24}
                style={{ position: 'relative', overflow: 'hidden' }}
              >
                <TabsPlayer
                  isEmbed={isEmbed}
                  media={media}
                  live={live}
                  playlist={playlist}
                  webTvId={webTvId}
                  pollId={pollId}
                  title={videoTitle}
                  isMuted={isMuted}
                />
              </Col>
              {/* {!isEmbed && (
            <Description
              title={title}
              description={content?.description}
              contentType={contentType}
              language={videoLanguage}
              playlist={playlist}
              collapsed
            />
          )} */}
            </Content>
            {/* {sidebarHasContent && screens.sm && (
          <Sider
            trigger={null}
            collapsible
            collapsed={mode === 'large' ? true : collapsed}
            collapsedWidth={0}
            width={Math.ceil(windowWidth / 3)}
            theme={pageTheme}
            style={{
              maxHeight: screens.sm
                ? `calc(${height > 0 ? `100vh - ${height}px` : '100vh'})`
                : 'initial',
              position: 'sticky',
              top: 0,
              zIndex: 111,
              boxShadow: webTvId
                ? ''
                : 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
            }}
          >
            <TabsPlayer
              isEmbed={isEmbed}
              media={media}
              live={live}
              playlist={playlist}
              webTvId={webTvId}
              title={title}
              isMuted={isMuted}
              collapsed={mode === 'large' ? true : collapsed}
              setCollapsed={setCollapsed}
            />
          </Sider>
        )} */}
            <span
              style={{
                width: 20
              }}
            ></span>
            {content?.pageSetting.shouldShowDiscussion && (
              <div
                style={{
                  width: '430px'
                }}
              >
                <Discussion discussionId={content?.discussion.id} />

                <div
                  style={{
                    marginTop: '50px',
                    textAlign: 'justify'
                  }}
                >
                  <p
                    style={{
                      paddingLeft: '30px'
                    }}
                  >
                    <img src="/help.png" width={'40px'} /> AIDE
                  </p>
                  <ul
                    style={{
                      backgroundColor: '#e8f3f7',
                      fontSize: '12px',
                      padding: '20px'
                    }}
                  >
                    <li>
                      Si la vidéo ne fonctionne pas, assurez-vous d’utiliser la
                      version la plus à jour de votre navigateur pour une
                      performance optimale. Le cas échéant, changez de
                      navigateur.
                    </li>
                    <li>
                      Si le flux vidéo saccade, fermez les autres onglets du
                      navigateur ou, pour les mobiles, les applications non
                      utilisées. Si trop d’applications restent ouvertes, tous
                      les processus sont ralentis, surtout la lecture vidéo.
                    </li>
                    <li>
                      En cas de problème, vous pouvez nous contacter au numéro{' '}
                      <br></br>
                      <span style={{ fontWeight: 'bold' }}>0 806 110 305</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {disableMultiSession &&
              (privacy === 'inscription' || privacy === 'private') && (
                <MultiSession privacyId={privacyId} />
              )}
          </Layout>
        }
        <footer>
          <div
            style={{
              width: '100%'
            }}
          >
            <img
              src={'/footer.png'}
              alt="logo"
              style={{
                width: 'inherit'
              }}
            />
          </div>
        </footer>
        <style jsx>
          {`
          .icon-theater {
            display: inline-block;
            position: relative;
          }
          .icon-theater:before {
            font-family Nuevo;
            font-size: 26px;
            font-weight: 400;
            content: "\e907";
          }
          .icon-theater.collapsed:before {
            content: "\e906";
          }
          .sidebar-aside {
            display: flex;
            align-items: start;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            width: 65px;
            z-index: 100;
            height: 100%;
            cursor: pointer;
            opacity: ${collapsed || mode === 'large' ? 1 : 0};
            pointer-events: ${collapsed || mode === 'large' ? 'all' : 'none'};
            transition: all .2s ease-in-out;
          }
          .sidebar-badge {
            padding: 16px 4px;
          }
          .sidebar-icon {
            opacity: .8;
            transition: all .2s ease-in-out;
          }
          .sidebar-background {
            position: absolute;
            right: 0;
            width: 60px;
            background: linear-gradient(90deg, transparent,rgba(255,255,255,.2));
            height: 100%;
            transform: translateX(60px);
            transition: all .2s ease-in-out;
          }

          .sidebar-aside:hover .sidebar-background {
            transform: translateX(0px);
          }
          .sidebar-aside:hover .sidebar-icon {
            opacity: 1;
          }
      `}
        </style>
      </Layout>
    </>
  );
};

export default Main;
